@import "./bootstrap";

#header {
  padding: 3rem 0;
  transition: padding 0.2s, background-color 0.2s;

  .container {
    height: 1.5rem;
  }

  .navbar-brand {
    padding: 0;
    height: 125%;

    img {
      transform: scale(1.25);
      transform-origin: left;
    }
  }

  img {
    height: 100%;
    width: auto;
  }

  &.scrolled {
    background: map-get($theme-colors, dark);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    padding: 2rem 0;
  }
}

.logo {
  height: 100%;
}

.stripe {
  width: 5rem;
  height: 0.5rem;
  margin-bottom: 1rem;
  background: map-get($theme-colors, primary);
}

// Round all buttons
.btn {
  border-radius: 50rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;

  &-primary {
    color: map-get($theme-colors, light);
  }

  &-outline-primary:not(:disabled):hover,
  &-outline-primary:not(:disabled):active {
    color: map-get($theme-colors, light) !important;
  }

  &-light,
  &-light:hover {
    color: map-get($theme-colors, primary) !important;
    font-weight: bold;
  }
}

h3 {
  opacity: 0.8;
}

#hero {
  display: flex;
  flex-direction: column;
  background-image: url("img/hero.jpg");
  background-color: map-get($theme-colors, dark);
  background-position: center;
  background-size: cover;
  height: 100vh;
  min-height: 40rem;
  justify-content: center;
  position: relative;

  .wave {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 15rem;

    @media (max-width: 768px) {
      height: 6rem;
    }
  }
}

.story-section {
  display: flex;
  flex-direction: column;
  background-size: cover;
  padding-bottom: 5rem;
}

.business-image {
  justify-content: flex-start;
}

.student-section,
.business-section,
#conditions-section {
  position: relative;
  padding: 5rem 0;

  > .background {
    position: absolute;
    top: 0;
    left: 0;

    background-position: center;
    background-size: cover;
    height: 100%;

    @media (max-width: 768px) {
      position: absolute;
      width: 100%;
      height: 5rem;
      margin-bottom: 1rem;
      opacity: 0.15;
    }
  }

  @media (max-width: 768px) {
    padding-top: 0;

    h2,
    h3 {
      line-height: 5rem;
      text-shadow: 0 1px map-get($theme-colors, light);
      margin-bottom: 1rem;
    }
  }
}

.student-section > .background {
  background-image: url("./img/student.jpg");
}

.business-section > .background {
  background-image: url("./img/business.jpg");
  right: 0;
  left: unset;
}

/* Business Section */
#partner-section {
  background: #f7f7f7;
  padding: 4rem 0;
  overflow: hidden;

  .glide__slide {
    // background: red;
    background: white;
    // border: 1px solid map-get($theme-colors, primary);

    a {
      display: block;
      height: 6rem;
      padding: 0 1rem;
      display: flex;
      justify-content: center;
      border-radius: 0;

      img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
      }
    }
  }

  .glide__arrows {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    visibility: hidden;

    .glide__arrow {
      visibility: visible;
      opacity: 0.25;

      transform: translateX(-65%);

      &.glide__arrow--right {
        transform: translateX(65%);
      }
    }
  }
}

/* Quiz Section */
#quiz-section {
  display: block;
  background-image: url("img/quiz.jpg");
  background-size: cover;
  background-position: center;
  padding: 3rem 0;

  filter: saturate(0.75);

  transition: filter 0.2s;

  &:hover {
    filter: saturate(1);
    text-decoration: none;
  }
}

/* Footer */
footer {
  img {
    width: 8rem;
    max-width: 100%;
  }

  .social-links {
    img {
      width: 1.5rem;
    }
  }
}

.icon {
  height: 2rem;
  width: 2rem;
}

.icon-large {
  width: 5rem;
  height: 5rem;
}
