$theme-colors: (
  "primary": #fe7740,
  "secondary": #fff,
  "dark": #343434
);

$font-family-base: "raleway-light", sans-serif;
@import "node_modules/bootstrap/scss/bootstrap";

@import "./fonts/fonts.css";

// Required Core Stylesheet
@import "node_modules/@glidejs/glide/src/assets/sass/glide.core";

// Optional Theme Stylesheet
// @import "node_modules/@glidejs/glide/src/assets/sass/glide.theme";

@import "node_modules/aos/src/sass/aos";
