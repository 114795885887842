/** Raleway **/

@font-face {
  font-family: "raleway-medium";
  src: url("Raleway-Medium.ttf") format("truetype"),
    url('Raleway-Medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "raleway-light";
  src: url("Raleway-Light.ttf") format("truetype"),
    url('Raleway-Light.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "raleway-extra-light";
  src: url("Raleway-ExtraLight.ttf") format("truetype"),
    url('Raleway-ExtraLight.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "raleway-thin";
  src: url("Raleway-Thin.ttf") format("truetype"),
    url('Raleway-Thin.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

/** Roboto = leuke font voor <p> **/